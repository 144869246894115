<template>
  <div id="app">
		<div id="survey">
			<v-container>
				<v-alert v-model="alert.show" :type="alert.type" dense dismissible>{{ alert.text }}</v-alert>
				<div class="step" :active-step="step == 0">
					<div class="intro">
						<img style="max-width: 300px" src="https://visualartists.ie/wp-content/uploads/2018/10/DLR-logo.jpg" alt="">
						<h2 style="margin: 0;">DLR Mobility Survey</h2>
					</div>
				
					<p style="text-align: center;">This publicly funded research will help review mobility and congestion challenges in Dún Laoghaire Rathdown, by filling in this short survey you can help to make the County a better place to live and work!</p>
				
					<p style="text-align: center;">Complete the survey to be in with a chance to win a €300 One4All voucher.</p>
				
					<p style="text-align: center;">All personal data will be anonymised and stored in line with GDPR. By continuing you
						agree to our <a href="/privacy-policy" target="_blank">privacy policy</a>.</p>
				
					<div class="group">
            <span class="question-title">Name</span>
						<v-text-field v-model="data.name" placeholder="Full Name (optional)" solo dense hide-details></v-text-field>
					</div>
				
					<div class="group">
            <span class="question-title">Email address</span>
						<v-text-field v-model="data.email" placeholder="To let you know the result of the draw" type="email" solo dense hide-details></v-text-field>
					</div>
				</div>

				<div class="step" :active-step="step == 1">
					<div class="intro">
						<div class="icon">
							<img src="https://staging.sharo.io/img/testing-icon.svg" alt="">
						</div>
					</div>
				
				
				
					<div class="group">
						<span class="question-title">1. What company do you work for? (optional)</span>
            <v-text-field v-model="data.company.name" placeholder="Company name" solo dense hide-details></v-text-field>
					</div>
				
					<div class="group">
						<span class="question-title">2. Where is your place of work?</span>
						<v-select v-model="data.company.address" :items="places_of_work" label="Choose one" dense solo hide-details></v-select>
            <!-- <v-text-field :append-icon="input_checks.company_address" :loading="loaders.company_address" @change="address_change('company_address')" v-model="data.company.address.formatted" placeholder="Office address" solo dense hide-details></v-text-field> -->
					</div>

					<div class="group">
						<span class="question-title">3. What town or locality are you traveling from?</span>
            <v-text-field :append-icon="input_checks.address" :loading="loaders.address" @change="address_change('address')" v-model="data.address.formatted" placeholder="You address" solo dense hide-details></v-text-field>
					</div>

					<div class="group">
						<span class="question-title">4. What's your age group?</span>
						<div class="surveyInputGroup">
							<input id="one-option-age-survey" name="survey-age-group" type="radio" v-model="data.age" value="19-24">
							<label for="one-option-age-survey">19 to 24 years old</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="two-option-age-survey" name="survey-age-group" type="radio" v-model="data.age" value="25-34">
							<label for="two-option-age-survey">25 to 34 years old</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="three-option-age-survey" name="survey-age-group" type="radio" v-model="data.age" value="35-44">
							<label for="three-option-age-survey">35 to 44 years old</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="four-option-age-survey" name="survey-age-group" type="radio" v-model="data.age" value="45-54">
							<label for="four-option-age-survey">45 to 54 years old</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="five-option-age-survey" name="survey-age-group" type="radio" v-model="data.age" value="55-64">
							<label for="five-option-age-survey">55 to 64 years old</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="six-option-age-survey" name="survey-age-group" type="radio" v-model="data.age" value="65-99">
							<label for="six-option-age-survey">More than 64 years old</label>
						</div>
					</div>

					<div class="group">
						<span class="question-title">5. What's your gender?</span>
						<div class="surveyInputGroup">
							<input id="one-option-gender-survey" name="survey-gender-group" type="radio" v-model="data.gender" value="male">
							<label for="one-option-gender-survey">Male</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="two-option-gender-survey" name="survey-gender-group" type="radio" v-model="data.gender" value="female">
							<label for="two-option-gender-survey">Female</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="three-option-gender-survey" name="survey-gender-group" type="radio" v-model="data.gender" value="other">
							<label for="three-option-gender-survey">Other / I prefer not to say</label>
						</div>
					</div>
				</div>

				<div class="step" :active-step="step == 2">
					<div class="intro">
						<div class="icon">
							<img src="https://staging.sharo.io/img/testing-icon.svg" alt="">
						</div>
						<h2 style="margin-bottom: 0; margin-top: 10px;">Before Covid</h2>
					</div>
				
          <div class="group">
            <span class="question-title">6. Time and duration of your commute to work (24 hours)?</span>
            <v-row>
              <v-col sm="6" span="12">
								<span style="color: #333;">Time leaving home</span>
								<!-- <v-select v-model="data.commute.go.start" :items="times" label="Time (24h)" dense solo hide-details></v-select> -->
                <vue-timepicker class="timepicker" placeholder="Time" format="HH:mm" v-model="data.commute.go.start" :minute-interval="15"></vue-timepicker>
								<!-- <v-text-field type="time" v-model="data.commute.go.start" placeholder="Time leaving home (24 hours)" solo dense hide-details></v-text-field> -->
              </v-col>
              <v-col sm="6" span="12">
								<span style="color: #333;">Duration</span>
								<v-select v-model="data.commute.go.duration" :items="durations" label="Journey time" dense solo hide-details></v-select>
                <!-- <v-text-field type="number" suffix="minutes" v-model.number="data.commute.go.duration" placeholder="Journey time" solo dense hide-details></v-text-field> -->
              </v-col>
            </v-row>
          </div>

          <div class="group">
            <span class="question-title">7. Time and duration of your commute back home (24 hours)?</span>
            <v-row>
              <v-col sm="6" span="12">
								<span style="color: #333;">Time leaving work</span>
								<!-- <v-select v-model="data.commute.back.start" :items="times" label="Time (24h)" dense solo hide-details></v-select> -->
                <vue-timepicker class="timepicker" placeholder="Time" format="HH:mm" v-model="data.commute.back.start" :minute-interval="15"></vue-timepicker>
								<!-- <v-text-field type="time" v-model="data.commute.back.start" placeholder="Time leaving office (24 hours)" solo dense hide-details></v-text-field> -->
              </v-col>
              <v-col sm="6" span="12">
								<span style="color: #333;">Duration</span>
								<v-select v-model="data.commute.back.duration" :items="durations" label="Journey time" dense solo hide-details></v-select>
                <!-- <v-text-field type="number" suffix="minutes" v-model.number="data.commute.back.duration" placeholder="Journey time" solo dense hide-details></v-text-field> -->
              </v-col>
            </v-row>
          </div>

          <div class="group">
            <span class="question-title">8. How do you usually commute to work (outside of working from home)?</span>
            <v-select v-model="data.commute_mode" :items="options" label="Choose one" dense solo hide-details></v-select>
          </div>

          <div class="group">
            <span class="question-title">9. What was your main reason for choosing that mode?</span>
						<div class="surveyInputGroup">
							<input id="one-main-reason-choosing-mode-group" name="main-reason-choosing-mode-group" type="radio" v-model="data.reason_choosing_mode" value="cost">
							<label for="one-main-reason-choosing-mode-group">Cost</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-main-reason-choosing-mode-group" name="main-reason-choosing-mode-group" type="radio" v-model="data.reason_choosing_mode" value="convenience">
							<label for="two-main-reason-choosing-mode-group">Convenience</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-main-reason-choosing-mode-group" name="main-reason-choosing-mode-group" type="radio" v-model="data.reason_choosing_mode" value="time">
							<label for="three-main-reason-choosing-mode-group">Time</label>
						</div>

            <div class="surveyInputGroup">
							<input id="four-main-reason-choosing-mode-group" name="main-reason-choosing-mode-group" type="radio" v-model="data.reason_choosing_mode" value="comfort">
							<label for="four-main-reason-choosing-mode-group">Comfort</label>
						</div>

						<div class="surveyInputGroup">
							<input id="five-main-reason-choosing-mode-group" name="main-reason-choosing-mode-group" type="radio" v-model="data.reason_choosing_mode" value="other">
							<label for="five-main-reason-choosing-mode-group">Other</label>
						</div>
						<div v-if="data.reason_choosing_mode === 'other'">
            	<v-textarea v-model="data.reason_choosing_mode_other" solo label="Reason (optional)" rows="1" hide-details></v-textarea>
						</div>
          </div>

          <div class="group">
            <span class="question-title">10. Did you work from home before Covid?</span>

            <div class="surveyInputGroup">
							<input id="one-work-from-home-before-group" name="work-from-home-before-group" type="radio" v-model="data.wfh_before_frequency" value="never">
							<label for="one-work-from-home-before-group">Never</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-work-from-home-before-group" name="work-from-home-before-group" type="radio" v-model="data.wfh_before_frequency" value="rarely">
							<label for="two-work-from-home-before-group">Rarely (once or twice a month)</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-work-from-home-before-group" name="work-from-home-before-group" type="radio" v-model="data.wfh_before_frequency" value="regularly">
							<label for="three-work-from-home-before-group">Regularly (1 or 2 days a week)</label>
						</div>

						<div class="surveyInputGroup">
							<input id="four-work-from-home-before-group" name="work-from-home-before-group" type="radio" v-model="data.wfh_before_frequency" value="more_often_than_not">
							<label for="four-work-from-home-before-group">More often than not (3 or 4 days per week)</label>
						</div>

            <div class="surveyInputGroup">
							<input id="five-work-from-home-before-group" name="work-from-home-before-group" type="radio" v-model="data.wfh_before_frequency" value="always">
							<label for="five-work-from-home-before-group">Always (Never or rarely go to the office)</label>
						</div>
          </div>

          <div class="group">
            <span class="question-title">11. Parking (Choose which options apply to you)</span>
						<v-select v-model="data.parking" :items="[{ value: 'dedicated_space', text: 'I have a dedicated car parking space' }, { value: 'easy_find_parking', text: 'I easily find parking' }, { value: 'difficult_find_parking', text: 'I have trouble parking' }, { value: 'no_drive', text: 'I do not drive' }, { value: 'secure_place_lock_bike', text: 'I have a secure place to lock my bike' }, { value: 'pay_for_parking', text: 'I pay for parking' }]" multiple label="Choose as many as you want" dense solo hide-details></v-select>

            <!-- <div class="surveyInputGroup">
							<input id="one-car-parking-group" name="car-parking-group" type="checkbox" v-model="data.parking" value="dedicated_space">
							<label for="one-car-parking-group">I have a dedicated car parking space</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-car-parking-group" name="car-parking-group" type="checkbox" v-model="data.parking" value="easy_find_parking">
							<label for="two-car-parking-group">I easily find parking</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-car-parking-group" name="car-parking-group" type="checkbox" v-model="data.parking" value="difficult_find_parking">
							<label for="three-car-parking-group">I have trouble parking</label>
						</div>

            <div class="surveyInputGroup">
							<input id="four-car-parking-group" name="car-parking-group" type="checkbox" v-model="data.parking" value="no_drive">
							<label for="four-car-parking-group">I don't drive</label>
						</div>

						<div class="surveyInputGroup">
							<input id="five-car-parking-group" name="car-parking-group" type="checkbox" v-model="data.parking" value="secure_place_lock_bike">
							<label for="five-car-parking-group">I have a secure place to lock my bike</label>
						</div>

						<div class="surveyInputGroup">
							<input id="six-car-parking-group" name="car-parking-group" type="checkbox" v-model="data.parking" value="pay_for_parking">
							<label for="six-car-parking-group">I pay for parking</label>
						</div> -->
          </div>

					<div class="group">
            <span class="question-title">12. On average, how happy are you with your commute?</span>
            <request-happiness v-model="data.commute_happiness"></request-happiness>
          </div>
				</div>

				<div class="step" :active-step="step == 3">
					<div class="intro">
						<div class="icon">
							<img src="https://staging.sharo.io/img/testing-icon.svg" alt="">
						</div>
						<h2 style="margin-bottom: 0; margin-top: 10px;">After Covid</h2>
					</div>

					<div class="group">
            <span class="question-title">13. If given the option, would you choose to work from home when restrictions are lifted?</span>

            <div class="surveyInputGroup">
							<input id="one-work-from-home-after-group" name="work-from-home-after-group" type="radio" v-model="data.wfh_after_frequency" value="never">
							<label for="one-work-from-home-after-group">Never</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-work-from-home-after-group" name="work-from-home-after-group" type="radio" v-model="data.wfh_after_frequency" value="rarely">
							<label for="two-work-from-home-after-group">Rarely (once or twice a month)</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-work-from-home-after-group" name="work-from-home-after-group" type="radio" v-model="data.wfh_after_frequency" value="regularly">
							<label for="three-work-from-home-after-group">Regularly (1 or 2 days a week)</label>
						</div>
						
						<div class="surveyInputGroup">
							<input id="four-work-from-home-after-group" name="work-from-home-after-group" type="radio" v-model="data.wfh_after_frequency" value="more_often_than_not">
							<label for="four-work-from-home-after-group">More often than not (3 or 4 days per week)</label>
						</div>

            <div class="surveyInputGroup">
							<input id="five-work-from-home-after-group" name="work-from-home-after-group" type="radio" v-model="data.wfh_after_frequency" value="always">
							<label for="five-work-from-home-after-group">Always (Never or rarely go to the office)</label>
						</div>
          </div>

					<div class="group">
            <span class="question-title">14. Why would you prefer to work from home?</span>

            <div class="surveyInputGroup">
							<input id="one-reasons-work-from-home-after-group" name="reasons-work-from-home-after-group" type="radio" v-model="data.wfh_reasons" value="health">
							<label for="one-reasons-work-from-home-after-group">Health concerns</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-reasons-work-from-home-after-group" name="reasons-work-from-home-after-group" type="radio" v-model="data.wfh_reasons" value="childcare">
							<label for="two-reasons-work-from-home-after-group">Childcare</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-reasons-work-from-home-after-group" name="reasons-work-from-home-after-group" type="radio" v-model="data.wfh_reasons" value="transport">
							<label for="three-reasons-work-from-home-after-group">Time saved by not commuting</label>
						</div>

						<div class="surveyInputGroup">
							<input id="four-reasons-work-from-home-after-group" name="reasons-work-from-home-after-group" type="radio" v-model="data.wfh_reasons" value="productivity">
							<label for="four-reasons-work-from-home-after-group">More productive at home</label>
						</div>

						<div class="surveyInputGroup">
							<input id="five-reasons-work-from-home-after-group" name="reasons-work-from-home-after-group" type="radio" v-model="data.wfh_reasons" value="flexibility">
							<label for="five-reasons-work-from-home-after-group">Flexibility</label>
						</div>

            <div class="surveyInputGroup">
							<input id="six-reasons-work-from-home-after-group" name="reasons-work-from-home-after-group" type="radio" v-model="data.wfh_reasons" value="other">
							<label for="six-reasons-work-from-home-after-group">Other</label>
						</div>

						<div v-if="data.wfh_reasons === 'other'">
							<v-text-field v-model="data.wfh_reasons_other" placeholder="(Optional) Please state other" solo dense hide-details></v-text-field>
						</div>
          </div>

					<div class="group">
            <span class="question-title">15. Under what circumstances would you feel safe going back to the office?</span>

            <div class="surveyInputGroup">
							<input id="one-circumstances-safe-going-office-group" name="circumstances-safe-going-office-group" type="radio" v-model="data.circumstances_needed_work_office" value="asap">
							<label for="one-circumstances-safe-going-office-group">As soon as restrictions are lifted</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-circumstances-safe-going-office-group" name="circumstances-safe-going-office-group" type="radio" v-model="data.circumstances_needed_work_office" value="low_transmission_rate">
							<label for="two-circumstances-safe-going-office-group">When community transmission rate is low</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-circumstances-safe-going-office-group" name="circumstances-safe-going-office-group" type="radio" v-model="data.circumstances_needed_work_office" value="when_vaccinated">
							<label for="three-circumstances-safe-going-office-group">Once I am vaccinated</label>
						</div>

            <div class="surveyInputGroup">
							<input id="four-circumstances-safe-going-office-group" name="circumstances-safe-going-office-group" type="radio" v-model="data.circumstances_needed_work_office" value="other">
							<label for="four-circumstances-safe-going-office-group">Other</label>
						</div>

						<div v-if="data.circumstances_needed_work_office === 'other'">
							<v-text-field v-model="data.circumstances_needed_work_office_other" placeholder="(Optional) Please state other" solo dense hide-details></v-text-field>
						</div>
          </div>

					<div class="group">
            <span class="question-title">16. Which alternative transport modes would you feasibly use to get to work?</span>
						<v-select v-model="data.commute_mode_open_to_try" :items="options" multiple label="Choose as many as you want" dense solo hide-details></v-select>
            

						<div style="margin-top: 10px;" v-if="data.commute_mode_open_to_try === 'other#text' || (data.commute_mode_open_to_try || []).includes('other#text')">
							<v-text-field v-model="data.commute_mode_open_to_try_other" placeholder="(Optional) Please state other" solo dense hide-details></v-text-field>
						</div>
          </div>

					<div class="group">
            <span class="question-title">17. What would help you to choose an active commute option (walking, running or cycling)?</span>
						<v-select v-model="data.active_travel_facilities_needed" :items="facilities" multiple label="Choose as many as you want" dense solo hide-details></v-select>
            

						<div style="margin-top: 10px;" v-if="data.active_travel_facilities_needed === 'other' || (data.active_travel_facilities_needed || []).includes('other')">
							<v-text-field v-model="data.active_travel_facilities_needed_other" placeholder="(Optional) Please state other" solo dense hide-details></v-text-field>
						</div>
          </div>

					<div class="group">
            <span class="question-title">18. We want to improve sustainable mobility post-Covid, not simply go back to the old normal. Would you help us by installing a new mobility app that would measure your trips, CO2 and offer you rewards for active and sustainable travel? (All collected data will be aggregated and anonymous).</span>
						
						<div class="surveyInputGroup">
							<input id="one-install-active-app-group" name="install-active-app-group" type="radio" v-model="data.willing_try_tracking_app" value="yes">
							<label for="one-install-active-app-group" style="padding-right: 70px !important;">Yes, I am willing to help and try reduce my CO2 & save time</label>
						</div>

            <div class="surveyInputGroup">
							<input id="two-install-active-app-group" name="install-active-app-group" type="radio" v-model="data.willing_try_tracking_app" value="maybe">
							<label for="two-install-active-app-group">I'd like to help but would like to know more</label>
						</div>

            <div class="surveyInputGroup">
							<input id="three-install-active-app-group" name="install-active-app-group" type="radio" v-model="data.willing_try_tracking_app" value="no">
							<label for="three-install-active-app-group">No, I am not interested</label>
						</div>
          </div>
				</div>

				<div class="step" :active-step="step == 4">
					<div class="intro">
						<div class="icon">
							<img src="https://staging.sharo.io/img/email-check.png" alt="">
						</div>
						<h2 style="margin-bottom: 0; margin-top: 10px;">Thanks, you're done!</h2>
						<p v-if="data.email" style="text-align: center;">We will notify you of the result of the giveaway to <b >{{ data.email }}</b>.</p>
					</div>
				</div>

				<div v-if="steps.length > step" class="group button-submit">
          <v-btn v-if="step != 0" @click="step = step - 1" rounded outlined style="margin-right: 10px;"><v-icon>mdi-chevron-left</v-icon> Back</v-btn>
          <v-btn :loading="loading" @click="next" rounded color="orange darken-1" dark>{{ steps[step].button }}<v-icon>mdi-chevron-right</v-icon></v-btn>
				</div>
			</v-container>
		</div>

		<div style="text-align: center; opacity: 0.7; position: absolute; top: 20px; right: 20px; background-color: rgba(255, 255, 255); z-index: 99999;">
			<span style="display: block; font-weight: 500; font-size: 12px;">Powered by</span>
			<img style="max-width: 80px;" :src="publicPath + 'logo.png'" alt="">
		</div>


		<!-- Dialog -->
			<v-dialog v-model="dialog_confirm_no_email" persistent max-width="650">
				<v-card>
					<v-card-title class="headline">
						Missing email
					</v-card-title>
					<v-card-text>If you want to be in with a chance to win the €300 One4All voucher, we need an email to contact you. Otherwise, you can fill out the survey without the email.</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						
						<v-btn
							color="grey darken-1"
							text
							@click="skipNext()"
						>
							<span style="font-size: 12px;">Continue without</span>
						</v-btn>
						<v-btn
							color="green"
							light
							@click="dialog_confirm_no_email = false"
						>
							<span style="font-size: 12px; color: white;">Enter email</span>
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
	</div>
</template>

<script>
import RequestHappiness from "../components/RequestHappiness.vue";
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import moment, { min } from "moment";

export default {
	components: { RequestHappiness, VueTimepicker },
  data: () => ({
		publicPath: process.env.BASE_URL,
    step: 0,
    steps: [
      { button: "Start" },
      { button: "Next" },
      { button: "Next" },
      { button: "Next" },
    ],
		loading: false,
		loaders: {
			company_address: false,
			address: false
		},
		dialog_confirm_no_email: false,
		input_checks: {
			company_address: "",
			address: ""
		},
		alert: {
			show: false,
			text: "",
			type: "error"
		},
		times: ['00:00', '00:15', '00:30', '00:45'],
		durations: [{ text: '0 - 15 min', value: 15 }, { text: '16 - 30 min', value: 30 }, { text: '31 - 45 min', value: 45 }, { text: '46 - 60 min', value: 60 }, { text: '61 - 75 min', value: 75 }, { text: '76 - 90 min', value: 90 }, { text: 'Over 90 min', value: 100 }],
    data: {
      name: '',
      email: '',
			company: {
				name: '',
				address: {
					formatted: "",
					location: { lat: 0, lon: 0 }
				}
			},
			address: {
				formatted: "",
					location: { lat: 0, lon: 0 }
			},
			age: '',
			gender: '',
			commute: {
				go: {
					start: '',
					end: '',
					duration: ''
				},
				back: {
					start: '',
					end: '',
					duration: ''
				}
			},
			commute_mode: '',
			reason_choosing_mode: '',
			commute_happiness: 0,
			wfh_before_frequency: '',
			parking: [],
			wfh_after_frequency: '',
			wfh_reasons: '',
			circumstances_needed_work_office: '',
			commute_mode_open_to_try: [],
			active_travel_facilities_needed: [],
			willing_try_tracking_app: ''
    },
		options: [
			{ value: 'private_transport#car', text: 'Driving a car' },
			{ value: 'active_travel#walk', text: 'On foot' },
			{ value: 'active_travel#cycle', text: 'Bicycle' },
			{ value: 'public_transport#public_bus', text: 'Public bus' },
			{ value: 'public_transport#bus', text: 'Minibus or coach' },
			{ value: 'public_transport#luas', text: 'Luas' },
			{ value: 'public_transport#train', text: 'Train or dart' },
			{ value: 'private_transport#motorcycle', text: 'Motorcycle or moped' },
			{ value: 'private_transport#escooter', text: 'E-scooter or e-bike' },
			{ value: 'private_transport#carpool', text: 'Passenger in a car with driver going to the same destination' },
			{ value: 'private_transport#carpool-taxi', text: 'Passenger in a car with driver going to a different location' },
			{ value: 'private_transport#taxi', text: 'Taxi' },
			{ value: 'private_transport#carvan', text: 'Van' },
			{ value: 'other#text', text: 'Other means' },
			{ value: 'NaN#wfh', text: 'Work mainly at or from home' },
		],
		facilities: [
			{ value: "showers", text: "Shower areas (provided / increased / improved)" },
			{ value: "lockers", text: "Lockers provided" },
			{ value: "drying_room", text: "Drying room for gear provided" },
			{ value: "cycle_parking", text: "Cycle parking increased" },
			{ value: "cycle_lanes", text: "Cycle lanes increased" },
			{ value: "footpath", text: "Footpath increased" },
			{ value: "other", text: "Other" },
		],
		places_of_work: [
			{
				text: "Blackrock",
				value: {
					formatted: "Blackrock",
					location: {
						lat: 53.3021597,
						lon: -6.177833499999999
					}
				}
			},
			{
				text: "Dundrum",
				value: {
					formatted: "Dundrum",
					location: {
						lat: 53.2931758,
						lon: -6.246175
					}
				}
			},
			{
				text: "Dún Laoghaire",
				value: {
					formatted: "Dún Laoghaire",
					location: {
						lat: 53.2943958,
						lon: -6.133866599999999
					}
				}
			},
			{
				text: "Glencullen-Sandyford",
				value: {
					formatted: "Glencullen-Sandyford",
					location: {
						lat: 53.27896980000001,
						lon: -6.2163425
					}
				}
			},
			{
				text: "Killiney-Shankill",
				value: {
					formatted: "Killiney-Shankill",
					location: {
						lat: 53.2332663,
						lon: -6.1237578
					}
				}
			},
			{
				text: "Stillorgan",
				value: {
					formatted: "Stillorgan",
					location: {
						lat: 53.2877953,
						lon: -6.2035681
					}
				}
			}
		]
  }),
  methods: {
		showAlert(text) {
			this.alert.show = true;
			this.alert.text = text;
		},

    async next() {
			if (this.step === 0 && !this.verifyStepZero()) {
				return;
			}

			if (this.step === 1 && !this.verifyStepOne()) {
				return;
			}

			if (this.step === 2 && !this.verifyStepTwo()) {
				return;
			}

			if (this.step === 3 && !this.verifyStepThree()) {
				return;
			}

			if (this.step === 3) {
				this.loading = true;
				let result = await this.submit();
				console.log(result);
				if (result) {

				}
			}

			// Loading and accept
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
				this.step = this.step + 1;
				window.scrollTo(0,0);
				this.alert.show = false;
			}, 1000);
    },

		skipNext() {
			this.dialog_confirm_no_email = false;
			this.step = this.step + 1;
			window.scrollTo(0, 0);
		},

		// Step 0
		verifyStepZero() {
			// If they typed an email and it is not correct format
			if (this.data.email.trim() && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.data.email)) {
				this.showAlert("We need a valid email address");
				return false;
			} else if (!this.data.email.trim()) {
				this.dialog_confirm_no_email = true;
				return false;
			}


			return true;
		},

		// Step 1
		verifyStepOne() {
			if (!this.data.company.address.formatted.trim() || 
					!this.data.address.formatted.trim() || 
					!this.data.age.trim() || 
					!this.data.gender.trim()) {
				this.showAlert("Kindly answer all mandatory questions");
				return false;
			}

			// if (this.input_checks.company_address !== "mdi-checkbox-marked-circle") {
			// 	this.showAlert("Fill in a valid office address");
			// 	return false;
			// }

			if (this.input_checks.address !== "mdi-checkbox-marked-circle") {
				this.showAlert("Fill in a valid address");
				return false;
			}

			return true;
		},

		// Step 2
		verifyStepTwo() {
			if (!this.data.commute.go.start.trim() || 
					!this.data.commute.go.duration || 
					!this.data.commute.back.start.trim() || 
					!this.data.commute.back.duration || 
					!this.data.commute_mode.trim() ||
					!this.data.reason_choosing_mode.trim() ||
					this.data.parking.length < 1 ||
					!this.data.commute_happiness) {
				this.showAlert("Kindly answer all mandatory questions");
				return false;
			}

			return true;
		},

		// Step 3
		verifyStepThree() {
			if (!this.data.wfh_after_frequency.trim() || 
					!this.data.wfh_reasons.trim() || 
					!this.data.circumstances_needed_work_office.trim() ||
					this.data.commute_mode_open_to_try.length < 1 ||
					this.data.active_travel_facilities_needed.length < 1 ||
					!this.data.willing_try_tracking_app.trim()) {
				this.showAlert("Kindly answer all mandatory questions");
				return false;
			}

			return true;
		},

		async address_change(val) {
			if (val === "company_address") {
				this.loaders.company_address = true;


				let result = await (await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.data.company.address.formatted)}&components=country:IE&key=AIzaSyApGepgI3SX24dwNQ0o2MUJBemf0MXvKVk`)).json();
				
				// If address not found
				if (result.results.length < 1) {
					this.loaders.company_address = false;
					this.input_checks.company_address = "mdi-cancel";
					return;
				}

				// Set options
				this.data.company.address.formatted = result.results[0].formatted_address;
				this.data.company.address.location.lat = result.results[0].geometry.location.lat;
				this.data.company.address.location.lon = result.results[0].geometry.location.lng;
				this.loaders.company_address = false;
				this.input_checks.company_address = "mdi-checkbox-marked-circle";
				
			} else if (val === "address") {
				this.loaders.address = true;

				let result = await (await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(this.data.address.formatted)}&components=country:IE&key=AIzaSyApGepgI3SX24dwNQ0o2MUJBemf0MXvKVk`)).json();
				
				// If address not found
				if (result.results.length < 1) {
					this.loaders.address = false;
					this.input_checks.address = "mdi-cancel";
					return;
				}

				// Set options
				this.data.address.formatted = result.results[0].formatted_address;
				this.data.address.location.lat = result.results[0].geometry.location.lat;
				this.data.address.location.lon = result.results[0].geometry.location.lng;
				this.loaders.address = false;
				this.input_checks.address = "mdi-checkbox-marked-circle";
			}
		},

		async submit() {
			// Create end times
			this.data.commute.go.start = moment.utc().hour(Number(this.data.commute.go.start.split(':')[0])).minute(Number(this.data.commute.go.start.split(':')[1])).toISOString();
			this.data.commute.back.start = moment.utc().hour(Number(this.data.commute.back.start.split(':')[0])).minute(Number(this.data.commute.back.start.split(':')[1])).toISOString();
			this.data.commute.go.end = moment.utc(this.data.commute.go.start).add(this.data.commute.go.duration, 'minutes').toISOString();
			this.data.commute.back.end = moment.utc(this.data.commute.back.start).add(this.data.commute.back.duration, 'minutes').toISOString();

			// Submit
			let result = await fetch('https://sharosurveybackend-gsdw65ugpa-ew.a.run.app', {
				method: 'post',
				headers: {
      		'Content-Type': 'application/json'
				},
				body: JSON.stringify({ survey: "Sandyford", ...this.data }),
			});

			console.log(await result.json());

			return true;
		}
  },
	created() {
		let hours = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
		let minutes = ['00', '15', '30', '45'];

		for (let h = 0; h < hours.length; h++) {
			for (let m = 0; m < minutes.length; m++) {
				this.times.push(`${hours[h]}:${minutes[m]}`);
			}
		}
	}
}
</script>

<style>
@import url(./style.css);

* {
  font-family: 'Poppins', sans-serif;
}

.timepicker {
	width: 100%;
}

.timepicker input {
	height: 38px !important;
	font-family: 'Poppins', sans-serif;
	width: 100% !important;
	font-size: 16px !important;
	border-radius: 4px;
	border: 0 !important;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px !important;
}

.timepicker .hours .active, .timepicker .minutes .active {
	background-color: rgb(25, 118, 210) !important;
}

.step:not([active-step=true]) {
  display: none;
}

.v-alert {
	position: sticky;
	top: 10px;
	z-index: 9999;
}

.v-btn__content {
	font-size: 12px !important;
}
</style>
