<template>
  <div class="container privacy-policy" style="max-width: 850px;">
    <h1 style="text-align: center;">Privacy Policy</h1>
    <p style="text-align: center;">Effective date: 10 May 2021</p>

    <p>The DLR Mobility Survey (“the Survey”) is operated by Sharo Mobility, a private limited company incorporated and registered in Ireland. Sharo Mobility is the data controller and can be contacted by email at hello@sharo.io.</p>

    <h3>Purpose</h3>
    <p>The purpose of this privacy policy (this "Privacy Policy") is to inform Survey Users (“the Users”) of the following:</p>
    <ol>
      <li>The personal data we will collect;</li>
      <li>Use of collected data;</li>
      <li>Who has access to the data collected; and</li>
      <li>The rights of our Users.</li>
    </ol>
    <p>The Survey is a publicly funded research that will help review mobility and congestion challenges in Dún Laoghaire Rathdown.</p>

    <h3>GDPR</h3>
    <p>We adhere to the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016, known as the General Data Protection Regulation (the "GDPR").</p>

    <h3>Consent</h3>
    <p>By filling out the Survey, our Users agree that they consent to the conditions set out in this Privacy Policy. </p>
    <p>The legal basis for us processing your personal data is that you have provided your consent to that processing, you may withdraw your consent at any time. If you withdraw your consent, it will not make processing which we completed before you withdrew your consent unlawful.</p>
    <p>You can withdraw your consent by contacting Sharo Mobility via email at hello@sharo.io.</p>

    <h3>Legal Basis for Processing</h3>
    <p>We collect and process personal data about Users in Ireland only when we have a legal basis for doing so under Article 6 of the GDPR.</p>
    <p>We rely on the following legal basis to collect and process the personal data of our Users:</p>
    <ol><li>sers have provided their consent to the processing of their data for the purpose of the Survey as set out in this Privacy Policy.</li></ol>

    <h3>Personal Data We Collect </h3>
    <p>We only collect data that helps us achieve the purpose of the Survey as set out in this Privacy Policy. All data collected is entered by Users as they answer the Survey questions.</p>
    <p>We optionally collect the following personal data for the purpose of communicating the result of a €300 One4All gift card draw:</p>
    <ol>
      <li>First and last name;</li>
      <li>Email address;</li>
    </ol>

    <p>We will collect the following personal data which will be stored anonymously on our database, separate from user’s name and email address. Therefore this data cannot be associated with the user name or email.</p>
    <ol>
      <li>Company (optional)</li>
      <li>Work location (Local Election Area)</li>
      <li>Home location (town/locality)</li>
      <li>Age group</li>
      <li>Gender;</li>
    </ol>
    <p></p>

    <h3>How We Use Personal Data</h3>
    <p>Data collected for the Survey will only be used for the purpose specified below:</p>
    <ol>
      <li>Analyse and review mobility and congestion challenges in Dún Laoghaire Rathdown;</li>
    </ol>
    <p></p>

    <h3>Who We Share Personal Data With</h3>
    <h4>Employees</h4>
    <p>We may disclose user data to any member of our organisation who reasonably needs access to user data to achieve the purpose set out in this Privacy Policy.</p>

    <h4>Third Parties </h4>
    <p>We may share collected Survey data and resulting report with the following third parties:</p>
    <ol>
      <li>DLR County Council.</li>
      <li>Trinity College Dublin.</li>
    </ol>
    <p>We may only share the collected data with the aforementioned third parties to achieve the purpose set out in this Privacy Policy. We will not sell or share your personal data with other third parties.</p>

    <h3>How Long We Store Personal Data </h3>
    <p>User data will be stored until the purpose the data was collected for has been achieved. </p>

    <h3>How We Protect Your Personal Data </h3>
    <p>We store your personal data at an EU data center on Google Cloud Platform which has strong security features to protect your data from unauthorized access, disclosure, processing or destruction of information they hold, including:</p>
    <ol>
      <li>encrypting Service Data at rest and while in transit between their facilities.</li>
      <li>regularly reviewing their information collection and storage practices, including physical security measures, to prevent unauthorized access to their systems.</li>
      <li>restricting access to data to our employees who need that information in order to process it for the purpose set out in this Privacy Policy.</li>
    </ol>
    <p></p>
  
    <h3>Your Rights as a User </h3>
    <p>Under the GDPR, you have the following rights:</p>
    <ol>
      <li>Right to be informed; </li>
      <li>Right of access; </li>
      <li>Right to rectification; </li>
      <li>Right to erasure; </li>
      <li>Right to restrict processing; </li>
      <li>Right to data portability; and </li>
      <li>Right to object. </li>
    </ol>
    <p></p>

    <h3>Children</h3>
    <p>The Survey is intended for adult Users only. We do not knowingly collect or use personal data from children under 16 years of age. If we learn that we have collected personal data from a child under 16 years of age, the personal data will be deleted as soon as possible. If a child under 16 years of age has provided us with personal data their parent or guardian may email us at hello@sharo.io.</p>

    <h3>How to Access, Modify, Delete, or Challenge the Data Collected </h3>
    <p>If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, if you would like your data to be deleted or modified in any way, or if you would like to exercise any of your other rights under the GDPR, please contact us by email at hello@sharo.io.</p>

    <h3>Complaints</h3>
    <p>If you have any complaints about how we process your personal data, please contact us through the contact method listed in the Contact Information section so that we can, where possible, resolve the issue. If you feel we have not addressed your concern in a satisfactory manner you also have the right to directly make a complaint to a supervisory authority by contacting the Data Protection Commission.</p>
  
    <h3>Contact Information</h3>
    <p>If you have any questions, concerns or complaints, you can contact us by email at hello@sharo.io. </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.privacy-policy p {
  font-size: 16px !important;
  text-align: justify;
}

.privacy-policy h2, .privacy-policy h3, .privacy-policy h4, .privacy-policy h5, .privacy-policy h6 {
  font-weight: 600;
}

</style>